import Seo from '@/components/shared/custom/seo-component';
import { Pages } from '@/constants/rutas';
import HomePageComponent from '@/components/home';

const HomePage = () => {
    return (
        <div className="home-container">
            <Seo pageId={Pages.NOTICIAS} />
            <div className="container">
                <HomePageComponent />
            </div>
        </div>
    );
};

export default HomePage;
