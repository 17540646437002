import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { Button, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import axiosApiInstance from '@/api/shared/axios-instance';
import URL_FACTORY from '@/helpers/url-helper';
import { Rutas } from '@/constants/rutas';

const HomeCarruselNewsComponent = () => {
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const router = useRouter();
    const navigate = (url) => router.push(url);

    useEffect(() => {
        const url = URL_FACTORY.URL_GET_PUBLISHED_ARTICLES;
        const payload = { skip: 0, take: 15 };

        axiosApiInstance
            .post(url, payload)
            .then((response) => {
                setArticles(response.data?.articles);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const renderArticles = () => {
        return articles.slice(0, 8).map((article) => ({
            original: article.cover,
            thumbnail: article.cover,
            description: article.title,
            slug: article.slug,
        }));
    };

    const handleImageClick = () => {
        const selectedArticle = articles[currentIndex];

        let url = '';
        if (selectedArticle && selectedArticle.url?.length > 1) {
            url =selectedArticle.url;
        } else if (selectedArticle && selectedArticle.slug) {
            url = Rutas.NOTICIAS + '/' + selectedArticle.slug;
        }

        router.push(url);
    };

    return (
        <div style={{ width: '100%', margin: 'auto' }} className="carousel-noticias">
            <h2 style={{ fontSize: '20px', fontWeight: '700' }}>Últimas noticias de fantasy fútbol</h2>
            {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={350} className="mb-25" />
            ) : (
                <>
                    <ImageGallery
                        items={renderArticles()}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        autoPlay={true}
                        slideDuration={1000}
                        slideInterval={10000}
                        onClick={handleImageClick}
                        onSlide={(index) => setCurrentIndex(index)} // Track the current index
                    />
                    <Button
                        style={{ width: '100%', marginBottom: '12px' }}
                        variant="outlined"
                        onClick={() => navigate(Rutas.NOTICIAS)}
                    >
                        Ver todas las noticias
                    </Button>
                </>
            )}
        </div>
    );
};

export default HomeCarruselNewsComponent;
