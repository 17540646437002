import { useRouter } from 'next/router';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Typography, Button, Tabs, Tab } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { parseISO } from 'date-fns';
import AdsenseHomeHorizontal1 from '../ads-components/adsense/home/adsense-home-horizontal-1';
import FixturesNewCardComponent from '../page-components/matches-card/fixture-new-card-component';
import HomeFixturesByCalendar from './home-fixtures-by-calendar';
import { Context } from '@/context';
import { getCompetitionName } from '@/helpers/leagues-helper';
import { Rutas } from '@/constants/rutas';
import { LEAGUES } from '@/constants/leagues';
import { isMatchInProgress } from '@/helpers/fixtures-helper';

const customLeagueOrder = [
    LEAGUES.LA_LIGA,
    LEAGUES.EUROPE_SUPER_CUP,
    LEAGUES.AMISTOSOS_CLUBES,
    LEAGUES.COPA_AMERICA,
    LEAGUES.CHAMPIONS_LEAGUE,
    LEAGUES.EUROCOPA,
    LEAGUES.PREMIER_LEAGUE,
    LEAGUES.FIFA_FRIENDLIES,
];

const HomeFixtures = () => {
    const { state } = useContext(Context);
    const { fixtures } = state;
    const router = useRouter();
    const [groupedFixtures, setGroupedFixtures] = useState({});
    const [expandedLeagues, setExpandedLeagues] = useState({});
    const [activeTabs, setActiveTabs] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [expandedDate, setExpandedDate] = useState(false);
    const [expandedLive, setExpandedLive] = useState(false);
    const [availableDates, setAvailableDates] = useState([]);

    useEffect(() => {
        if (fixtures?.rounds?.length > 0) {
            let allFixtures = [];

            fixtures.rounds.forEach((round) => {
                allFixtures = allFixtures.concat(round.fixtures);
            });

            const now = new Date();

            const grouped =
                allFixtures?.reduce((acc, fixture) => {
                    const leagueId = fixture.leagueId;
                    if (!acc[leagueId]) {
                        acc[leagueId] = { upcoming: [], past: [] };
                    }
                    if (new Date(fixture.date) >= now) {
                        acc[leagueId].upcoming.push(fixture);
                    } else {
                        acc[leagueId].past.push(fixture);
                    }
                    return acc;
                }, {}) || {};

            setGroupedFixtures(grouped);

            // Calculate available dates
            const dates = allFixtures.map((fixture) => fixture.date).sort();
            setAvailableDates(dates);

            // Set default active tab for each league
            const defaultTabs = Object.keys(grouped).reduce((acc, leagueId) => {
                acc[leagueId] = grouped[leagueId].upcoming.length > 0 ? 0 : 1; // 0 for "Próximos partidos", 1 for "Últimos partidos"
                return acc;
            }, {});
            setActiveTabs(defaultTabs);

            // Select today's date or the next available date
            const parsedDates = dates.map((date) => parseISO(date)).sort((a, b) => a - b);
            const todayDate = parsedDates.find((date) => date >= now) || parsedDates[0];
            setSelectedDate(todayDate);
        }
    }, [fixtures]);

    const toggleLeagueExpansion = (leagueId) => {
        setExpandedLeagues((prev) => ({
            ...prev,
            [leagueId]: !prev[leagueId],
        }));
    };

    const handleFixture = (fixture) => {
        router.push(Rutas.PARTIDO + '/' + fixture.fixtureId + '/alineaciones-probables');
    };

    const handleTabChange = (_, newValue, leagueId) => {
        setActiveTabs((prev) => ({
            ...prev,
            [leagueId]: newValue,
        }));
    };

    const renderLiveFixtures = () => {
        const liveFixtures = Object.keys(groupedFixtures)
            .map(Number)
            .reduce((acc, leagueId) => {
                const liveGames = groupedFixtures[leagueId].past.filter((x) =>
                    isMatchInProgress(x?.fixtureStatus)
                );
                if (liveGames.length > 0) {
                    acc[leagueId] = liveGames;
                }
                return acc;
            }, {});

        const allLiveFixtures = Object.values(liveFixtures).flat();
        const fixturesToShow = expandedLive ? allLiveFixtures : allLiveFixtures.slice(0, 3);

        if (fixturesToShow?.length > 0) {
            return (
                <div className="af-card league-fixtures" style={{ marginBottom: '24px' }}>
                    <div className="af-card__head">
                        <Typography className="af-card__head-text" variant="h6">
                            Partidos en vivo
                        </Typography>
                    </div>
                    {fixturesToShow.length === 0 ? (
                        <div
                            style={{
                                boxShadow: '0 1px 2px 1px #e1e1e1',
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '16px',
                                height: '40px',
                            }}
                        >
                            <Typography variant="h6">No hay partidos en vivo</Typography>
                        </div>
                    ) : (
                        <div className="fixtures-card-container">
                            <FixturesNewCardComponent
                                fixtures={fixturesToShow}
                                handleFixture={handleFixture}
                            />
                        </div>
                    )}
                    {allLiveFixtures.length > 3 && (
                        <Button
                            onClick={() => setExpandedLive(!expandedLive)}
                            endIcon={expandedLive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            style={{ textTransform: 'capitalize', width: '100%' }}
                        >
                            {expandedLive ? 'Ver menos' : 'Ver más'}
                        </Button>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderFixturesByLeague = () => {
        const leagueIds = Object.keys(groupedFixtures).map(Number);
        leagueIds.sort((a, b) => {
            const indexA = customLeagueOrder.indexOf(a);
            const indexB = customLeagueOrder.indexOf(b);
            if (indexA === -1 && indexB === -1) {
                return a - b;
            }
            if (indexA === -1) {
                return 1;
            }
            if (indexB === -1) {
                return -1;
            }
            return indexA - indexB;
        });

        return leagueIds.map((leagueId) => {
            const { upcoming, past } = groupedFixtures[leagueId];
            const competitionName = getCompetitionName(Number(leagueId));
            const isExpanded = expandedLeagues[leagueId];
            const displayedFixtures =
                activeTabs[leagueId] === 0
                    ? upcoming?.sort((a, b) => new Date(a.date) - new Date(b.date))
                    : past?.sort((a, b) => new Date(b.date) - new Date(a.date));
            const fixturesToShow = isExpanded ? displayedFixtures : displayedFixtures.slice(0, 3);

            return (
                <Fragment key={leagueId}>
                    <div className="af-card league-fixtures" style={{ marginBottom: '16px' }}>
                        <div className="af-card__head">
                            <Typography className="af-card__head-text" variant="h6">
                                {competitionName}
                            </Typography>
                        </div>
                        {upcoming.length > 0 && past.length > 0 ? (
                            <Tabs
                                value={activeTabs[leagueId]}
                                onChange={(event, newValue) => handleTabChange(event, newValue, leagueId)}
                                aria-label="fixture tabs"
                                style={{ marginBottom: '16px', display: 'flex' }}
                            >
                                <Tab
                                    label="Próximos partidos"
                                    style={{ width: '50%' }}
                                    disabled={upcoming.length === 0}
                                />
                                <Tab
                                    label="Últimos partidos"
                                    style={{ width: '50%' }}
                                    disabled={past.length === 0}
                                />
                            </Tabs>
                        ) : null}
                        <div className="fixtures-card-container">
                            <FixturesNewCardComponent
                                fixtures={fixturesToShow}
                                handleFixture={handleFixture}
                            />
                        </div>
                        {displayedFixtures.length > 3 && (
                            <Button
                                onClick={() => toggleLeagueExpansion(leagueId)}
                                endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                style={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {isExpanded ? 'Ver menos' : 'Ver más'}
                            </Button>
                        )}
                    </div>
                </Fragment>
            );
        });
    };

    const renderFixturesByDate = () => {
        if (!fixtures?.rounds?.length) return null;

        const selectedDateFixtures = fixtures.rounds
            .flatMap((round) => round.fixtures)
            .filter((fixture) => new Date(fixture.date).toDateString() === selectedDate.toDateString());

        if (selectedDateFixtures.length === 0) {
            return (
                <div
                    style={{
                        boxShadow: '0 1px 2px 1px #e1e1e1',
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '16px',
                        height: '40px',
                    }}
                >
                    <Typography variant="h6">No hay partidos para esta fecha</Typography>
                </div>
            );
        }

        const fixturesToShow = expandedDate ? selectedDateFixtures : selectedDateFixtures.slice(0, 3);

        return (
            <div className="af-card league-fixtures" style={{ marginBottom: '16px' }}>
                <div className="af-card__head">
                    <Typography className="af-card__head-text" variant="h6">
                        Partidos del {selectedDate.toLocaleDateString()}
                    </Typography>
                </div>
                <div className="fixtures-card-container">
                    <FixturesNewCardComponent fixtures={fixturesToShow} handleFixture={handleFixture} />
                </div>
                {selectedDateFixtures.length > 3 && (
                    <Button
                        onClick={() => setExpandedDate(!expandedDate)}
                        endIcon={expandedDate ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        style={{ textTransform: 'capitalize', width: '100%' }}
                    >
                        {expandedDate ? 'Ver menos' : 'Ver más'}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div>
            {renderLiveFixtures()}
            <AdsenseHomeHorizontal1 />
            <HomeFixturesByCalendar
                selectedDate={selectedDate}
                onSelectDate={setSelectedDate}
                availableDates={availableDates}
            />
            {renderFixturesByDate()}
            <Typography
                variant="h5"
                style={{
                    marginBottom: '16px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    fontSize: '20px',
                }}
            >
                Partidos por competiciones
            </Typography>

            {renderFixturesByLeague()}
        </div>
    );
};

export default HomeFixtures;
