import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import HomeActivities from './home-activities';
import HomeFixtures from './home-fixtures';
import HomeCarruselNewsComponent from './home-carrusel-news';
import axiosApiInstance from '@/api/shared/axios-instance';
import URL_FACTORY from '@/helpers/url-helper';
import { isElementVisible } from '@/helpers/html-helper';
import HomeFlashList from '@/components/home/home-flash-list';

const HomePageComponent = () => {
    const [noticiasFlash, setNoticiasFlash] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!isVisible) return;
        const url = URL_FACTORY.GetNoticiasFlash;

        const payload = {
            skip: 0,
            take: 5,
            ignore: '',
            teamSlug: '',
        };

        axiosApiInstance
            .post(url, payload)
            .then((response) => {
                setNoticiasFlash(response.data.noticias);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [isVisible]);

    useEffect(() => {
        function handleScroll() {
            const result = isElementVisible('noticias-flash-container');

            if (result) {
                setIsVisible(true);
            }
        }

        window.addEventListener('scroll', handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="">
            <Typography variant="h1" style={{ fontWeight: 600, marginBottom: '16px', fontSize: '24px' }}>
                Partidos, eventos y noticias fantasy
            </Typography>

            <div className="row home-container__main-row mb-25">
                <div className="col-12 col-lg-8">
                    <HomeFixtures />
                </div>
                <div className="col-12 col-lg-4" id="noticias-flash-container">
                    <HomeCarruselNewsComponent />
                    <HomeActivities customTake={5} isPage={false} showHeader={true} />
                    <HomeFlashList
                        take={5}
                        header="Noticias flash de fantasy fútbol"
                        hideHeader={false}
                        hideSearch={true}
                        noticiasFlash={noticiasFlash}
                    />
                </div>
            </div>
        </div>
    );
};

export default HomePageComponent;
