export const isScrolledIntoView = (elem) => {
    if (!elem) return false;

    try {
        const rect = elem.getBoundingClientRect();
        const isInView = rect.top < window.innerHeight && rect.bottom >= 0;
        return isInView;
    } catch (e) {
        return false;
    }
};

export const isElementVisible = (id) => {
    try {
        const elem = document.getElementById(id);
        return isScrolledIntoView(elem);
    } catch (e) {
        return false;
    }
    return result;
};
