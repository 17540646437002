import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Skeleton } from '@mui/material';
import RightArrow from '../shared/icons/right-arrow';
import CustomImageComponent from '../shared/custom/custom-image';
import InputMaterial from '../shared/custom/input-material';
import CalendarIcon from '../shared/icons/calendar-icon';
import NoticiasFlashIcon from '../shared/icons/noticias-flash-icon';
import NoticiasFlashButton from '../noticias-flash/noticias-flash-button';
import { getPlayerIcon, getPlayersStatus, replaceDiacritis } from '@/helpers/players-helper';
import { getDateMMddYYYYHHmm } from '@/helpers/datetime-helper';
import { Rutas } from '@/constants/rutas';
import { getMasterPlayerImage, getTeamImageById } from '@/helpers/images-helper';

const HomeFlashList = ({
    skip,
    take,
    hideHeader,
    buttonText,
    loadExtra,
    header,
    hideSearch,
    noticiasFlash,
    cssClass,
}) => {
    const [noticias, setNoticias] = useState(noticiasFlash ?? []);
    const [localSkip, setLocalSkip] = useState(skip ?? 0);
    const [localTake] = useState(take ?? 50);
    const [search, setSearch] = useState('');
    const router = useRouter();

    useEffect(() => {
        setNoticias(noticiasFlash);
    }, [noticiasFlash]);

    const handleClick = (noticia) => {
        router.push(`${Rutas.NOTICIAS_FLASH}/${noticia.slug}`);
    };

    const filterNoticias = (title, search) => {
        if (!title || !search) return true;
        return replaceDiacritis(title).indexOf(replaceDiacritis(search)) > -1;
    };

    const groupNoticiasByFecha = () => {
        const groupedNoticias = {};
        noticias
            ?.filter((noticia) => filterNoticias(noticia?.player?.name, search))
            ?.forEach((noticia) => {
                const fecha = getDateMMddYYYYHHmm(noticia.created).split(' ')[0];
                if (!groupedNoticias[fecha]) {
                    groupedNoticias[fecha] = [];
                }
                groupedNoticias[fecha].push(noticia);
            });
        return groupedNoticias;
    };

    const getNoticiaFlashCard = (noticia, index) => {
        return (
            <div key={index} className="flash-list-card" onClick={() => handleClick(noticia)}>
                <div className="flash-list-card__info">
                    <CustomImageComponent
                        src={getMasterPlayerImage(noticia.player, 60)}
                        width={40}
                        height={40}
                        alt={'jugador'}
                    />
                    <div className="flash-list-card__top">
                        <div className="flash-list-card__top-section">
                            <p className="flash-list-card__name">{noticia.player.name}</p>
                            <div className="flash-list-card__team-image">
                                <CustomImageComponent
                                    src={getTeamImageById(noticia.player.teamId, 30)}
                                    width={20}
                                    height={20}
                                    alt={'equipo'}
                                />
                                <p className="flash-list-card__team-name" style={{ marginBottom: 0 }}>
                                    {noticia.player.teamName}
                                </p>
                            </div>
                        </div>
                        <div className="flash-list-card__top-section flash-list-card__top-section-right">
                            <p className="flash-list-card__updated">{getDateMMddYYYYHHmm(noticia.created)}</p>
                            <p className="flash-list-card__link">Ver noticia flash</p>
                        </div>
                    </div>
                </div>
                <div className="flash-list-card__status">
                    <div className="general-transfer-status">
                        <div className="general-transfer-status__info">
                            {getPlayerIcon(noticia?.previous?.status, noticia?.previous?.statusLevel)}
                            {getPlayersStatus(noticia?.previous?.status, noticia?.previous?.statusLevel)}
                        </div>
                        <div>
                            <RightArrow />
                        </div>
                        <div className="general-transfer-status__info">
                            {getPlayerIcon(
                                noticia?.generalStatus?.status,
                                noticia?.generalStatus?.statusLevel
                            )}
                            {getPlayersStatus(
                                noticia?.generalStatus?.status,
                                noticia?.generalStatus?.statusLevel
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const groupedNoticias = groupNoticiasByFecha();

    const handleLoadFlash = () => {
        if (loadExtra) {
            setLocalSkip(localSkip + localTake);
        } else {
            router.push(Rutas.NOTICIAS_FLASH);
        }
    };
    const getNoticiasflashComponent = () => {
        if (noticias?.length > 0) {
            return Object.keys(groupedNoticias).map((fecha, index) => (
                <div key={index} className="fecha-div">
                    <div className="fecha">
                        <div>
                            <CalendarIcon iconWidth={15} iconHeight={15} color={'#000'} />
                            {fecha}
                        </div>
                        <div>
                            <span>{groupedNoticias[fecha].length}</span>
                            <NoticiasFlashIcon color={'#000'} iconHeight={15} iconWidth={15} />
                        </div>
                    </div>
                    {groupedNoticias[fecha].map((noticia, cardIndex) =>
                        getNoticiaFlashCard(noticia, cardIndex)
                    )}
                </div>
            ));
        }

        return (
            <div>
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
                <Skeleton style={{ marginBottom: '16px' }} variant="rectangular" width="100%" height={107} />
            </div>
        );
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    return (
        <div className={`${cssClass} flash-list`}>
            {noticias?.length > 0 && hideHeader !== true && (
                <p className="flash-list__header">{header ?? 'Últimas noticias flash'}</p>
            )}

            {hideSearch !== true && (
                <div className="mb-25">
                    <InputMaterial label="Nombre" onChange={handleSearch} value={search} />
                </div>
            )}

            {getNoticiasflashComponent()}

            {noticias?.length > 0 && (
                <div className="mb-25">
                    <NoticiasFlashButton
                        text={buttonText ?? 'Ver todas las Noticias Flash'}
                        onClick={handleLoadFlash}
                    />
                </div>
            )}
        </div>
    );
};

export default HomeFlashList;
